import { IRepositoryRequirements } from 'repositories/repositories.common'
import { CreateDealsProps, ICreateDealInteractiveCombo } from './Deals.props'
import { Fetch } from 'utils'

export function DealsRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/deals`

  async function create ({ type, payload }: CreateDealsProps) {
    const url = payload.deliveryCenterId ? `${basePath}/ddc` : `${basePath}`

    const dealsKey: Record<typeof type, string> = {
      DISCOUNT: 'discount',
      FREE_GOOD: 'freeGood',
      STEPPED_DISCOUNT: 'scaledDiscounts',
      STEPPED_FREE_GOOD: 'scaledFreeGoods',
      PRICE_REDUCTION: 'priceReduction',
      CROSS_DISCOUNT: 'crossDiscount',
      SPECIAL_OFFERS: 'specialOffers'
    }

    return Fetch.post({
      url,
      data: { ...payload, deal: { [dealsKey[type]]: payload.deal }, dealType: type },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboForAccount (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboWithVariantsForAccount (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo/variants`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  return {
    create,
    createDealInteractiveComboForAccount,
    createDealInteractiveComboWithVariantsForAccount
  }
}
